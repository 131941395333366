import _ from 'lodash';

import { TimeValue } from '@/contexts';
import { eachDatePointOfInterval, getProperty } from '@/utils';
import { formatDateWithGranularity } from './formatDateWithGranularity';

export type MappedResponse<T extends {}> = T & {
  __formattedDate: string;
  __timeStamp: number;
};

export const mapStats = <
  Input extends {},
  Output extends MappedResponse<Input>
>(
  time: TimeValue,
  stats: Input[],
  dateField: keyof Input,
  allMetrics: string[],
): Output[] => {
  const dateArr = eachDatePointOfInterval(time);

  const statsWithFormattedDateValue = stats.map((a) => {
    const dateString = a[dateField];
    let __formattedDate;
    if (
      typeof dateString === 'string' ||
      typeof dateString === 'number' ||
      dateString instanceof Date
    ) {
      __formattedDate = formatDateWithGranularity(
        new Date(dateString),
        time.granularity,
      );
    }
    return { ...a, __formattedDate };
  });

  const groupedStats = _.keyBy(statsWithFormattedDateValue, '__formattedDate');

  return dateArr.map((d) => {
    const foundValue =
      getProperty(
        groupedStats,
        formatDateWithGranularity(d, time.granularity),
      ) || {};

    const mappedMetrics = allMetrics.reduce(
      (acc, metric) => ({
        ...acc,
        [metric]: getProperty(foundValue, metric) || 0,
      }),
      {},
    );

    return {
      ...foundValue,
      ...mappedMetrics,
      __timeStamp: d.getTime(),
    } as Output;
  });
};
