import { format } from 'date-fns';

import { GranularityEnum } from '@/globalTypes';

export const formatDateWithGranularity = (
  date: Date,
  granularity: GranularityEnum,
) => {
  switch (granularity) {
    case GranularityEnum.Second:
      return format(date, 'yyyy-MM-dd HH:mm:ss');

    case GranularityEnum.Minute:
    case GranularityEnum.Hour:
      return format(date, 'yyyy-MM-dd HH:mm');

    case GranularityEnum.Day:
      return format(date, 'EEE yyyy-MM-dd');

    case GranularityEnum.Week:
      return `week ${format(date, 'II (yyyy)')}`;

    case GranularityEnum.Month:
      return format(date, 'MMM (yyyy)');

    case GranularityEnum.Quarter:
      return format(date, 'qqq (yyyy)');

    default:
      return format(date, 'yyyy');
  }
};
