export const allNumericFields = [
  'amountAdjustments',
  'amountBonusAdjustments',
  'amountCashbacks',
  'amountDeposits',
  'amountFailedDeposits',
  'amountFailedWithdrawals',
  'amountFtd',
  'amountWin',
  'amountWithdrawableWinnings',
  'amountWithdrawals',
  'ftd',
  'ftdOfNrc',
  'ftp',
  'gameWin',
  'ggr',
  'hold_',
  'jpc',
  'margin',
  'ngr',
  'nrc',
  'nrcByNsc',
  'nsc',
  'numAdjustments',
  'numBlockAccountRequests',
  'numCancelSelfExclusionRequests',
  'numCashbacks',
  'numCloseAccountRequests',
  'numDeposits',
  'numFailedDeposits',
  'numFailedWithdrawals',
  'numReopenAccountRequests',
  'numSelfExclusionRequests',
  'numTotalCloseAccountRequests',
  'numUniqueActivePlayers',
  'numUniqueDepositors',
  'numUniquePlayers',
  'numUniqueSessions',
  'numWithdrawals',
  'turnover',
  'wagers',
];
